import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/Seo"

const SuccessPage = ({ data }) => {
  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Registration Successful"} height={300}>
      <SEO title="Registration Successful" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-6 text-center">
            <h1>
              Your registration was successful. You will be notified via your
              email with further details.
            </h1>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query RegisterSuccessPageQuery {
    banner: allFile(filter: { name: { eq: "register_success_1440x400" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default SuccessPage
